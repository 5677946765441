/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SectionContainer from "../components/SectionContainer";
import ImageGallery from "../components/ImageGallery";
import FeaturedProducts from "../components/FeaturedProducts";
import Content, { HTMLContent } from "../components/Content";

export const ProjectPostTemplate = ({
  content,
  contentComponent,
  assistant,
  producer,
  featuredimage,
  featuredimagealt,
  photocredits,
  releaseyear,
  title,
  imagegallery,
  helmet,
  relatedproductstitle,
  products,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <SectionContainer>
      {helmet || ""}
      <section
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
          alignItems: "center",
          mb: [0, null, 4],
        }}
      >
        {featuredimage && (
          <div
            sx={{
              flex: 2,
              width: "100%",
              div: { height: ["85vh", "100vh", "100%"] },
              position: "relative",
            }}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: featuredimagealt,
              }}
            />
            <div
              sx={{
                position: "absolute",
                zIndex: 2,
                fontFamily: "body",
                top: "0px",
                pl: [4, 5],
                color: "white",
                display: ["flex", null, "none"],
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <h1 sx={{ fontSize: 6, mb: [3, 4], textTransform: "uppercase" }}>
                {title}
              </h1>
              {producer && <p sx={{ fontSize: 0 }}>{producer}</p>}
              {assistant && <p sx={{ fontSize: 0 }}>{assistant}</p>}
              <p sx={{ mb: [3, 4], fontSize: 0 }}>{releaseyear}</p>
            </div>
            {photocredits && (
              <p
                sx={{
                  fontSize: 0,
                  pl: [4, 5, "0px"],
                  pt: [2, 4, "0px"],
                }}
              >
                {photocredits}
              </p>
            )}
          </div>
        )}
        <article
          sx={{
            flex: 1,
            px: [4, 5, 6],
            mb: [6, null, "0px"],
            mt: [5, null, "0px"],
          }}
        >
          <div sx={{ display: ["none", null, "block"] }}>
            <h1 sx={{ fontSize: 6, mb: [3, 4] }}>{title}</h1>
            {producer && <p sx={{ fontSize: 0 }}>{producer}</p>}
            {assistant && <p sx={{ fontSize: 0 }}>{assistant}</p>}
            <p sx={{ mb: [3, 4], fontSize: 0 }}>{releaseyear}</p>
          </div>
          <div sx={{ my: 4, p: { my: 3 } }}>
            <PostContent content={content} />
          </div>
        </article>
      </section>
      {imagegallery && imagegallery.length > 0 && (
        <ImageGallery images={imagegallery} />
      )}
      <div
        sx={{
          my: 8,
          mx: [4, 7],
        }}
      >
        {products && products.length > 0 && (
          <FeaturedProducts products={products} title={relatedproductstitle} />
        )}
      </div>
    </SectionContainer>
  );
};

ProjectPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  assistant: PropTypes.string,
  producer: PropTypes.string,
  featuredimage: PropTypes.object,
  featuredimagealt: PropTypes.string,
  photocredits: PropTypes.string,
  releaseyear: PropTypes.string,
  imagegallery: PropTypes.array,
  relatedproductstitle: PropTypes.string,
  products: PropTypes.array,
};

const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ProjectPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        releaseyear={post.frontmatter.releaseyear}
        photocredits={post.frontmatter.photocredits}
        relatedproductstitle={post.frontmatter.relatedproductstitle}
        products={post.frontmatter.products}
        assistant={post.frontmatter.assistant}
        producer={post.frontmatter.producer}
        featuredimage={post.frontmatter.featuredimage}
        featuredimagealt={post.frontmatter.featuredimagealt}
        imagegallery={post.frontmatter.imagegallery}
        helmet={
          <Helmet titleTemplate="%s | Project">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={post.frontmatter.summary} />
          </Helmet>
        }
      />
    </Layout>
  );
};

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProjectPost;

export const pageQuery = graphql`
  query ProjectPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        releaseyear
        photocredits
        relatedproductstitle
        products
        producer
        assistant
        summary
        imagegallery {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        featuredimagealt
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
