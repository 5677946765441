/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const ImageGallery = ({ images }) => {
  return (
    <section
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridGap: 2,
        px: [0, 4, 0],
      }}
    >
      {images.map((image, index) => {
        return (
          <div
            sx={{
              gridColumn: ["span 6", "span 3"],
              div: {
                height: "100%",
              },
              "&:nth-of-type(3n)": {
                gridColumn: ["span 6", "span 2"],
                gridRow: ["auto", "2"],
              },
              "&:nth-of-type(4n)": {
                gridColumn: ["span 6", "1 / 3"],
                gridRow: ["auto", "3"],
              },
              "&:nth-of-type(5n)": {
                gridColumn: ["span 6", "3/7"],
                gridRow: ["auto", "2 / 4"],
              },
              "&:nth-of-type(6n)": {
                gridColumn: ["span 6", "span 3"],
                gridRow: ["auto", "4"],
              },
              "&:nth-of-type(7n)": {
                gridColumn: ["span 6", "span 3"],
                gridRow: ["auto", "4"],
              },
              "&:nth-of-type(8n)": {
                gridColumn: ["span 6", "span 2"],
                gridRow: ["auto", "5"],
              },
              "&:nth-of-type(9n)": {
                gridColumn: ["span 6", "1 / 3"],
                gridRow: ["auto", "6"],
              },
              "&:nth-of-type(10n)": {
                gridColumn: ["span 6", "3/7"],
                gridRow: ["auto", "5 / 7"],
              },
            }}
            key={index}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: image.image,
                alt: image.alttext,
              }}
            />
          </div>
        );
      })}
    </section>
  );
};

export default ImageGallery;

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
};
