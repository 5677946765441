/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const SectionContainer = ({ children }) => (
  <div
    sx={{
      pt: ["0px", null, "75px"],
      mx: ["0px", null, 5],
    }}
  >
    {children}
  </div>
);

export default SectionContainer;

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
